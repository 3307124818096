.loading-container{
  background-color: #F2F2F2;
}

.loading-img{
  position: fixed;
  width: 70px;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}