.doc-nav {
    height:100px;
    margin-top: 0px;
    background-color: #0d8abc;
    color: #fff;
    font-family: 'Open Sans', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    opacity: 0.95;
}

.logo {
    height:75px;
    width: 65px;
}

.dd-pos{
    position: absolute;
    left: 150px;
    float: left;
}

.dd-button {
    background-color: #daeaf6;
    color: #0d8abc;
    font-family: 'Open Sans', sans-serif;
}

.dd-button:active, .open .dropdown-toggle {
    background:#FFF !important; 
    color:#000 !important;
}

DropdownButton {
    background-color: #daeaf6;
    color: #0d8abc;
    font-family: 'Open Sans', sans-serif; 
}

button {
    background-color: #daeaf6;
    color: #0d8abc;
    font-family: 'Open Sans', sans-serif;
}

button:hover {
    background-color: #FF008E;
    color: #fff;
    font-family: 'Open Sans', sans-serif;
}