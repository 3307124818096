.tryContainer {
  height:100%;
  padding-top: 125px;
  background-color: #fff;
  color: #0d8abc;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.buttons {
  background-color: #daeaf6;
  color: #0d8abc;
  font-family: 'Open Sans', sans-serif;
  height:50px;
  border-radius: 7px;
}

.buttons:hover {
  background-color: #FF008E;
  color: #fff;
  font-family: 'Open Sans', sans-serif;
}

.tableData {
  width: 75%;
  font-family: 'Open Sans', sans-serif;
}

.table-head {
  background-color: #daeaf6;
  color: #0d8abc;
  
}

textarea {
  width: 60%;
  height: 30%;
}

.postman {
  height: 30px;
}