.infoContainer {
  height: 100%;
  padding: 50px;
  padding-top: 0px;
  background-color: #fff;
  color: #000;
  font-size: 14px;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.buttonsTop {
  background-color: #0d8abc;
  opacity: 0.9;
  color: white;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 600;
  width: 100px;
  height: 30px;
  border: none;
  margin-top: 15px;
  margin-bottom: 15px;
  border-radius: 0px;
  position: fixed;
  bottom: 30px;
  right: 30px;
}

.buttonsTop:hover {
  background-color: #ff008e;
  opacity: 0.9;
  color: white;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  height: 30px;
  font-weight: 600;
  text-decoration: none;
  width: 100px;
  border: none;
  border-radius: 0px;
}

code {
  background-color: #eee;
  margin: 10px;
}

h1 {
  color: #0d8abc;
  padding-bottom: 20px;
  font-family: "Poppins", sans-serif;
  font-size: x-large;
  /*font-weight: 400;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h2 {
  color: #0d8abc;
  font-size: large;
  padding-top: 30px;
  padding-bottom: 20px;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h3 {
  color: #0d8abc;
  font-size: x-large;
  padding-top: 30px;
  padding-bottom: 20px;
  font-family: "Poppins", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: #0d8abc;
}

a:hover {
  color: #ff008e;
  text-decoration: none;
}

.buttonLink {
  color: #fff;
}

.buttonLink:hover {
  color: #fff;
  text-decoration: none;
}

.postman {
  height: 30px;
}

.imgMD {
  padding: 20px;
  width: 80%;
}
